import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Tile" component="tile" variation="tile" hasReactVersion hasVueVersion="tile--default" hasAngularVersion="?path=/story/tiles--basic" codepen="qwZRWy" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Clickable tile" component="tile" variation="tile--clickable" hasReactVersion hasVueVersion="tile--clickable" hasAngularVersion="?path=/story/tiles--clickable" codepen="yrOgyM" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Selectable tile" component="tile" variation="tile--selectable" hasReactVersion hasVueVersion="tile--selectable" codepen="wZGgKK" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Expandable tile" component="tile" variation="tile--expandable" hasReactVersion hasVueVersion="tile--expandable" codepen="QPNdNG" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Grid tile" component="tile" variation="tile--grid" hasReactVersion mdxType="ComponentCode">
    </ComponentCode>
    <ComponentDocs component="tile" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      